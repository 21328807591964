<template>
  <div class="container" ref="wrapper">
          <van-divider>用户类型统计</van-divider>
    <table class="basic-table">
      <thead>
        <tr>
          <th>日期</th>
          <th>总数</th>
          <th>A3客户</th>
          <th>新客户</th>
          <th>待同步</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in answerList" :key="index">
          <td>{{ item.creationTime }}</td>
          <td>{{ item.totalCount }}</td>
          <td>{{ item.a3Count }}</td>
          <td>{{ item.newCount }}</td>
          <td>{{ item.notAsyncCount }}</td>
        </tr>
      </tbody>
    </table>

    <van-divider>用户打开填写统计</van-divider>
    <table class="basic-table" style="margin-bottom: 80px;">
      <thead>
        <tr>
          <th>日期</th>
       
          <th>打开数</th>
          <th>填写数</th>
          <th>填写率</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in articles" :key="index">
          <td>{{ item.creationTime }}</td>
          <td>{{ item.openCount }}</td>
          <td>{{ item.writeCount }}</td>
          <td>{{ parseInt(item.bindPercent) }}%</td>
        </tr>
      </tbody>
    </table>

    <div v-if="A3Error" style="text-align: center">
      <van-button type="info" @click="toBindA3">{{ A3Message }}</van-button>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import {
  getDeviceCountByUser,
  getAnswerCountByUser,
} from "@/api/questionnaire";
export default {
  name: "DeviceCount",
  props: {
    questionnaireId: String,
  },
  data() {
    return {
      scroll: 0,
      refreshing: false,
      upLoading: false,
      finished: false,
      articles: [],
      answerList: [],
      A3Error: false,
      A3Message: "去绑定A3工号",
      page: {
        current: 1,
        pageSize: 10,
        sorterType: 0,
        filterData: {
          title: "",
          phone: "",
          questionnaireId: "",
        },
      },
    };
  },
  watch: {
    questionnaireId(val, oldVal) {
      //普通的watch监听
      if (val != oldVal) {
        this.onSearch();
      }
    },
  },

  activated() {
    this.page.filterData.questionnaireId = this.questionnaireId;
    //this.onSearch();
    //  window.scrollTo(0, this.scroll);
    //  alert( this.$refs.wrapper.scrollTop);
    try {
      this.$refs.wrapper.scrollTop = this.scroll;
    } catch (error) {
      console.log(error);
    }
  },
  created() {
    this.page.filterData.questionnaireId = this.questionnaireId;
    this.onLoad();
  },

  mounted() {},

  methods: {
    onRefresh() {
      this.onSearch();
      this.refreshing = false;
    },
    onSearch() {
      this.finished = false;
      this.articles = [];
      this.answerList=[];
      this.page.current = 1;
      this.page.pageSize = 10;
      this.onLoad();
    },
    async onLoad() {
      this.upLoading = true;
      let aresult = await getDeviceCountByUser(this.questionnaireId); //({ ...this.page })
      if (!aresult.data.success) {
        Toast(aresult.data.errorMessage);
        this.upLoading = false;
        this.finished = true;
        if (aresult.data.errorCode == "A3Error1") {
          this.A3Error = true;
          this.A3Message = "去绑定A3工号";
        }
        if (aresult.data.errorCode == "A3Error2") {
          this.A3Error = true;
          this.A3Message = "去设置默认A3工号";
        }

        return;
      }

      this.articles.push(...aresult.data.data);
      this.upLoading = false;
      if (this.page.current * this.page.pageSize < aresult.data.total) {
        this.page.current++;
      } else {
        this.finished = true;
      }
      let answerListresult = await getAnswerCountByUser(this.questionnaireId); //({ ...this.page })
      this.answerList.push(...answerListresult.data.data);
    },
    toBindA3() {
      this.$router.push({
        path: "/user/binda3",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
}
table,
td,
th {
  border: 1px solid #ddd;
  text-align: left;
}

table {
  border-collapse: collapse;
  width: 100%;
}

th {
  padding: 5px;
}
td {
  padding: 5px;
}
</style>
